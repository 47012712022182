import { Link } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react'
import '../../styles/scss/roulette.scss'
import Modal from './Modal';
import Possibility from './Possiblity';
import axios from 'axios';

export default class Roulette extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isBackArrowActive:false,
            isNextArrowActive:true,
            selectedElement: {
                index: 0
            }
        }
    }

    componentDidMount() {
        this.setState({
            page: this.props.page
        }, () => {
            this.selectElement(undefined, 0);
            this.updatePageData()
        });

        this.startAutomaticLoop();
    }

    updatePageData = () => {
        axios.get(`https://versemail.azurewebsites.net/page/${this.props.page?.name}`).then(response => {
            if (response?.data) {
                this.setState({
                    page: {
                        ...this.state.page,
                        ...response?.data
                    },
                    originalPage: {...response?.data}
                }, () => this.selectElement(undefined, this.state.selectedElement?.index))
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    startAutomaticLoop = () => {
        let index = 1
        this.loop = setInterval(() => {
            if (index >= 3 || index >= this.state.page?.gallery.length) {
                index = 0
            }
            this.selectElement(undefined, index)
            index++
        }, 5000)
    }

    selectElement = (element, index, isFromInteraction) => {
        // if we don't specify element and only index, we search the elment
        if (!element && index !== undefined) {
            let elements = this.state.page?.gallery;
            if (!elements?.length) return;

            let i = 0;
            for (let e of elements) {
                if (i === index) {
                    element = e;
                    break;
                }
                i++
            }
        }


        this.setState({
            selectedElement: {
                ...element,
                index
            },
        })

        if (isFromInteraction && this.loop) {
            clearInterval(this.loop)
        }
    }

    navigate = (index) => {
        if(index > -1 && index < this.state.page?.gallery.length){
            let element = this.state.page?.gallery[index];
            this.setState({
                selectedElement:null
            }, () => {
                this.setState({
                    isNextArrowActive: (index < this.state.page?.gallery.length-1),
                    isBackArrowActive: (index > 0),
                    selectedElement: {
                        ...element,
                        index
                    }
                })
            })

            if (this.loop) {
                clearInterval(this.loop)
            }
        }
    }


    /**
     * Should have been done with css selectors but this was made to avoid unwanted behavior in different browsers
     * @param {*} index 
     * @returns 
     */
    getOptionClass = (index) => {
        switch (index) {
            case 0:
                return 'first'
            case 1:
                return 'second'
            case 2:
                return 'third';
            default:
                return ''
        }
    }

    isActive = (index) => {
        if (index === this.state.selectedElement?.index)
            return ' active'

        return ''
    }

    renderOptions = (gallery) => {
        if (!gallery?.length) return;

        return gallery.map((element, index) => {
            return (
                <div className={'option ' + this.getOptionClass(index) + this.isActive(index)} onClick={() => this.selectElement(element, index, true)}>
                    <div className='color-layer' />
                    <div className='text'>
                        {element?.title}
                    </div>

                    <div className='scanner-dot' />
                </div>
            )
        })
    }

    renderWatchVideo = (isContact) => {
        if (!isContact)
            return (
                <a href='#' className='watch-video'>
                    <i class='bx bxl-youtube'></i>
                    Watch the video
                </a>
            )

        return (
            <Link to={"/contact"} className='contact-us-btn'>
                contact us
            </Link>
        )
    }

    renderPossibilities = (possibilities) => {
        if (!possibilities?.length) return null;

        return possibilities.map((possibility,index) => {
            return <div style={{ backgroundImage: `url(${possibility?.src})` }}  onClick={() => this.selectPossibility(possibility,index)} />
        })
    }

    renderModal = () => {
        if (this.state.selectedPossibility)
            return <Possibility
                selectedElement={this.state.selectedPossibility}
                closeModal={this.selectPossibility}
            />
    }

    selectPossibility = (possibility,i) => {
        if (!possibility) return this.setState({selectedPossibility: possibility})
        this.setState({
            selectedPossibility: {
                title: 'Possibilities',
                ...possibility,
                source: possibility?.src,
                index:i,
                gallery: [...this.state.selectedElement?.possibilities]
            }
        })
        if (this.loop) {
            clearInterval(this.loop)
        }
    }

    renderNavigation = () => {
        if(this.state.page?.gallery.length > 1){
            return(
                <div className='navigation-controls'>
                    <span className='back arrow' style={{opacity:this.state.isBackArrowActive && 1}}  onClick={() => {
                        this.navigate(this.state.selectedElement.index-1)
                    }}></span>
                    <span className='indicator'> 
                        {`${this.state.selectedElement?.index + 1 + " /" + this.state.page?.gallery.length}`}
                    </span>
                    <span className='next arrow' style={{opacity:this.state.isNextArrowActive || 0}} onClick={() => {
                        this.navigate(this.state.selectedElement.index+1)
                    }}></span>
                </div>
            )
        }
    }

    renderSelectedElement = (selectedElement) => {
        return (
            <>
                <div className='roulette-target'>
                    {
                        selectedElement?.source &&
                        <div className='illustration' key={selectedElement?.source} style={{ backgroundImage: `url(${selectedElement?.source})` }} />
                    }
                    {this.renderNavigation()}
                </div>

                <div className='content-container left'>
                    <h4>Possibilities</h4>
                    <div className='possibilities-scroll'>
                        <div className='possibilities'>
                            {this.renderPossibilities(selectedElement?.possibilities)}
                        </div>
                    </div>
                </div>
                <div className='content-container right'>
                    <h3>{selectedElement?.title || 'Loading title...'}</h3>
                    <p>
                        {selectedElement?.content}
                    </p>

                    {
                        this.renderWatchVideo(selectedElement?.isContact)
                    }
                </div>
            </>
        )
    }

    render() {
        return (
            <div className='modeling-3d' id={this.state.page?.name}>
                <div className='titles-container'>
                    <h2>{this.state.page?.title?.text}</h2>
                    <h3>{this.state.page?.subtitle?.text}</h3>
                </div>

                <div className='content-menu'>
                    <div className='roulette'>
                        <div className='roulette-main'>
                            <div className='rectangle'>
                                {this.renderOptions(this.state.page?.gallery)}
                                {this.renderSelectedElement(this.state.selectedElement)}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderModal()}
            </div>
        )
    }
}
